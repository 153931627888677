import React from 'react'
import { Link } from 'react-router-dom'
import PulseLoader from "react-spinners/PulseLoader"
import { analytics, analyticsDebug } from '../backend/firebaseConfig';
import { logEvent } from 'firebase/analytics';
export default function BigButton(props: { label: string, linksTo?: string, onClick?: () => void, disabled?: boolean, style?: React.CSSProperties, loading?:boolean }) {    
    return (
        props.linksTo ?
        <div onClick={()=>{logEvent(analytics, props.label+'button pressed', {
            'debug_mode':analyticsDebug
          })}}>
            <Link className='big-button' to={props.linksTo} style={props.disabled ? { opacity: 0.6, pointerEvents: 'none', ...props.style } : props.style}>
                <h4>{props.label}</h4>
            </Link>
            </div>
            :
            <div onClick={()=>{logEvent(analytics, props.label+'button pressed', {
                'debug_mode':analyticsDebug
              })}}>
            <div className='big-button' onClick={props.onClick} style={{ cursor: 'pointer', pointerEvents: (props.disabled ? 'none' : 'auto'), opacity: props.disabled ? 0.6 : undefined, ...props.style }}>
                {props.loading? <><PulseLoader color="#FFFFFF" /> </> : <><h4 style={{ userSelect: 'none', WebkitUserSelect: 'none' }}> {props.label} </h4> </>}
            </div>
            </div>
    )
}