import React, { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom'
import { ContactInformation, ExactLocation, Message, Report, ReportField, ReportFieldBase } from '../../backend/databaseTypes'
import { Reports } from '../../backend/reports'
import BigButton from '../big-button'
import PhoneNumberInput from '../phone-number-input'
import FormBase from './form-base'
import BackButton from '../back-button'
import { useContext } from '../../routes/report'
import StepIndicator from '../step-indicator'
import { analytics, analyticsDebug } from '../../backend/firebaseConfig'
import { logEvent } from 'firebase/analytics'

export async function loader({ params }: any): Promise<string | null> {

    if (!params.tagID) {
        console.error('bad url')
        return null
    }

    return params.tagID
}

export default function ContactInfo() {
    logEvent(analytics, 'input contact info', {
        'debug_mode':analyticsDebug
      });
    const navigate = useNavigate()
    
    const context = useContext();
    const tagID = context.tagID

    useEffect(() => {
        if(context.location.lat == undefined){
            navigate(("/item/"+tagID))
        }
            }, []);
        useEffect(() => {
            window.addEventListener("beforeunload", alertUser);
            return () => {
              window.removeEventListener("beforeunload", alertUser);
            };
          }, []);
          const alertUser = (e:any) => {
            e.preventDefault();
            e.returnValue = "";
          };
    const [loading, setLoading] = useState(false)

    async function submit() {

        const fields: ReportFieldBase[] = []

        if (context.location) {
            fields.push(new ExactLocation(context.location.lat, context.location.lng))
        }

        if (context.descripton) {
            fields.push(new Message(context.descripton))
        }

        if (context.contactInfo.length !== 0) {
            fields.push(new ContactInformation(context.contactInfo))
        }

        const report = {
            tagID: tagID,
            fields: Object.fromEntries(fields.map((field) => {
                const obj = field.toPlainObject()
                return [obj.type, obj]
            })),
        }

        setLoading(true)
        const result = await Reports.sendReport(report).then((result) => {
            setLoading(false)
            if (result) {
                navigate('../thank-you')
            } else {
                console.error(result)
            }
        }
        )


        console.log(result)


    }


    function validateEmail(email:string) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }
    return (
        <FormBase>
            <StepIndicator link={`/item/${tagID}/description`} index={3} canGoBack />
            <h1>Get in touch!</h1>
            <p className='margin-top-16 margin-bottom-16'>If you want, you can leave your email so the owner can get in touch with you. Totally optional! (Leave blank to opt-out)</p>
            <PhoneNumberInput onValue={context.setContactInfo} defaultValue={context.contactInfo}/>
            <BigButton loading = {loading} label='Submit' onClick={() => submit()} disabled={(context.contactInfo.length !== 0 && !validateEmail(context.contactInfo) )|| loading} style={{ marginTop: 32 }}/>
        </FormBase>
    )
}