import { Link } from "react-router-dom";
import Logo from "./logo";

export default function MinNavbar() {
    return (
        <div className='logo-container'>
            <Link to={'/'}>
                <Logo />
            </Link>
        </div>
    )
}