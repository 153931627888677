import React from 'react';
import { useRouteError } from 'react-router-dom';
import FormBase from './components/reporting/form-base';
import Navbar from './components/navbar';
import PageContainer from './components/page-container';
import { logEvent } from 'firebase/analytics';
import { analytics, analyticsDebug } from './backend/firebaseConfig';

export default function ErrorPage() {

    const error = useRouteError() as any
    logEvent(analytics, 'error', {
        'debug_mode':analyticsDebug,
        'error':error
      });
    console.error(error)

    return (
        <PageContainer>
            <Navbar />
            <FormBase>
                <h1>Oops! That's an error.</h1>
                <i className='margin-top-32 margin-bottom-16'>{(error.statusText || error.message)}</i>
                <a href='/' className='link'>Back to safety</a>
            </FormBase>
        </PageContainer>
    )
}