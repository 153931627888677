import React, { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import { createSearchParams, useLoaderData, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom'
import { ExactLocation, Message, Report, ReportField } from '../../backend/databaseTypes'
import { Reports } from '../../backend/reports'
import BigButton from '../big-button'
import FormBase from './form-base'
import BackButton from '../back-button'
import { useContext } from '../../routes/report'
import StepIndicator from '../step-indicator'
import { logEvent } from 'firebase/analytics'
import { analytics, analyticsDebug } from '../../backend/firebaseConfig'

export async function loader({ params }: any): Promise<string | null> {

    if ( ! params.tagID) {
        console.error('bad url')
        return null
    }

    return params.tagID
}

export default function InputDescription() {
    logEvent(analytics, 'input description', {
        'debug_mode':analyticsDebug
      });
    const navigate = useNavigate()
    

    
    const context = useContext();
    const tagID = context.tagID
    const [loading, setLoading] = useState(false)
    useEffect(() => {
    if(context.location.lat == undefined){
        navigate(("/item/"+tagID))
    }
        }, []);
    useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        return () => {
          window.removeEventListener("beforeunload", alertUser);
        };
      }, []);
      const alertUser = (e:any) => {
        e.preventDefault();
        e.returnValue = "";
      };

      
    async function submit() {
        navigate({
            pathname: `../contactInfo`
        })
    
        
    }

    return (
        <FormBase>
            <StepIndicator link={`/item/${tagID}/location`} index={2} canGoBack />
            <h1>Where'd you leave it?</h1>
            <p className='margin-top-16 margin-bottom-16'>Ex. “I left it at the front desk,” “It’s at the back of room 112”</p>
            <textarea 
                maxLength={500} 
                autoFocus={true}
                defaultValue={context.descripton}
                onInput={(event) => {context.setDescripton((event.target as any).value)}}/>
            {
                <BigButton disabled={context.descripton.length < 1 || context.descripton.length > 500 || loading} onClick={submit} label='Next →' style={{ marginTop: 32 }} />
            }
        </FormBase>
    )
}