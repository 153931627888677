import React from 'react'
import { Outlet, useLoaderData, useOutletContext } from 'react-router-dom'
import Navbar from '../components/navbar'
import trySignIn from '../backend/signIn'
import { Reports } from '../backend/reports'
import FormBase from '../components/reporting/form-base'
import { logEvent } from 'firebase/analytics'
import { analytics, analyticsDebug } from '../backend/firebaseConfig'

type LoaderResult = 'ok' | 'missing-user-id' | 'user-id-invalid' | 'internal' | 'other-error'

export async function loader({ params }: any): Promise<{ userID: string | null, result: LoaderResult}> {

    await trySignIn()

    if (!params.userID || typeof params.userID !== 'string' || params.userID.length < 1) {
        return { userID: null, result: 'missing-user-id' }
    }

    console.log('checking code')
    const result = await Reports.canCreateReportTemplate(params.userID)
    console.log('checked code')

    let resultCode: LoaderResult

    switch (result) {
        case 'functions/invalid-argument':
        case 'functions/unauthenticated':
            resultCode = 'missing-user-id';
            break
        case 'functions/permission-denied':
            resultCode = 'user-id-invalid';
            break
        case 'functions/internal':
            resultCode = 'internal'
            break
        case 'functions/ok':
            resultCode = 'ok'
            break
        default:
            resultCode = 'other-error'
            break
    }

    return { userID: params.userID, result: resultCode }
}

type CreateTemplateReportContext = { createAsUserID: string }

export default function ReportTemplateRoot() {

    const result = useLoaderData() as { userID: string | null, result: LoaderResult}
    const resultCode = result.result
    const userID = result.userID
    logEvent(analytics, 'create report template', {
        'debug_mode':analyticsDebug,
        'user':userID
      });
    return (
        <>
            <Navbar />
            {
                resultCode === 'ok' ?
                    <Outlet context={{ createAsUserID: userID }} />
                    :
                    <ReportTemplateUserIDError error={resultCode} />
            }
        </>
    )
}

export const useCreateTemplateReportOutletContext = useOutletContext<CreateTemplateReportContext>

function ReportTemplateUserIDError(props: { error: LoaderResult }) {

    let message = ''

    switch (props.error) {
        case 'missing-user-id':
            message = `That link is missing an invite code. Please make sure to use the full link we provided you.`
            break
        case 'user-id-invalid':
            message = `That link is invalid. Please make sure to use the full link we provided you.`
            break
        case 'internal':
            message = `Your admin account hasn't been fully configured yet, please try again later.`
            break
        case 'other-error':
        default:
            message = `An error ocurred. Please try again.`
            break
    }

    return (
        <FormBase>
            <h1>Hmm...</h1>
            <p className='margin-top-16'>{message} If the error persists, please <a href={'mailto:support@beacontags.com'} className='link' target='_blank'>contact support</a>.</p>
        </FormBase>
    )
}



