import React, { useState } from 'react'
import ReactLoading from 'react-loading'
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom'
import { ContactInformation, ExactLocation, Message, Report, ReportField, ReportFieldBase } from '../../backend/databaseTypes'
import { Reports } from '../../backend/reports'
import BigButton from '../big-button'
import PhoneNumberInput from '../phone-number-input'
import FormBase from './form-base'
import { report } from 'process'
import BackButton from '../back-button'
import { analytics, analyticsDebug } from '../../backend/firebaseConfig'
import { logEvent } from 'firebase/analytics'
import { useContext } from '../../routes/report'
export default function SendReportTemplate() {

    const context = useContext();
    const tagID = context.tagID
    const navigate = useNavigate()
    const [reportID, setRawReportID] = useState("")
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showContactSupport, setShowContactSupport] = useState(false)

    function setReportID(reportID: string) {
        const noWhitespaceName = reportID.replaceAll(/\s+/g, '')
        const lowerCaseName = noWhitespaceName.toLocaleLowerCase()
        setRawReportID(lowerCaseName)
        setErrorMessage('')
        setShowContactSupport(false)
    }

    async function submit() {

        setErrorMessage('')
        setShowContactSupport(false)

        setLoading(true)
        const result = await Reports.sendReportTemplate(tagID, reportID)
        setLoading(false)

        if (result === 'functions/ok') {
            navigate('../thank-you')
            return
        }

        switch (result) {
            case 'functions/not-found':
                setErrorMessage(`No pre-filled report with that ID exists. Check your report ID and try again.`)
                break
            case 'functions/internal':
                setErrorMessage(`There's something wrong with that pre-filled report. Please try again or `)
                setShowContactSupport(true)
                break
            case 'functions/invalid-argument':
            default:
                setErrorMessage('Something went wrong. Check your report ID and try again.')
                break 
        }
    }
    logEvent(analytics, 'send report template', {
        'debug_mode':analyticsDebug
      });
    return (
        <FormBase>
            <BackButton linksTo={"/item/" + tagID} />
            <h1>Enter your pre-filled report ID</h1>
            <p className='margin-top-16 margin-bottom-16'>If you don't know which ID to use, please contact your institutional administrator.</p>
            <input
                onChange={(event) => setReportID(event.target.value)}
                placeholder="Report ID"
                value={reportID}
            />

            {errorMessage ? <p className='margin-top-16 margin-bottom-16 color-red'>{errorMessage}</p> : null}
            <BigButton loading={loading} label='Report' onClick={() => submit()} disabled={(reportID.length == 0) || loading} style={{ marginTop: 32 }} />
        </FormBase>
    )
}