import Footer from "./footer";

export default function PageContainer(props: { children?: React.ReactElement | React.ReactElement[] }) {
    return (
        <>
            <div className='page-container'>
                {props.children}
            </div>
            <Footer />
        </>
    )
}