import { Link } from "react-router-dom";
import Logo from "./logo";

export default function Navbar() {
    return (
        <div className='navbar-container'>
            <Link to={'/'}>
                <Logo color='white' />
            </Link>
        </div>
    )
}