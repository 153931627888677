import {
    doc,
    getDoc,
    setDoc,
} from 'firebase/firestore'
import {
    httpsCallable,
    HttpsCallableResult,
    FunctionsError,
    FunctionsErrorCode
} from 'firebase/functions'
import { Collections, ReportField } from './databaseTypes'
import { db, functions, auth } from './firebaseConfig'

const fileReport = httpsCallable(functions, 'fileReport')
const fileReportFromTemplate = httpsCallable(functions, 'fileReportFromTemplate')
const canCreateReportTemplate = httpsCallable(functions, 'canCreateReportTemplate')
const createReportTemplate = httpsCallable(functions, 'createReportTemplate')

export class Reports {





    public static async canReportID(tagID: string): Promise<string> {

        

        
        const query = await getDoc(doc(db, Collections.ReportableTagIDs, tagID))
        const tagExists = query.exists()
        if (!tagExists) {
            const queryLink = await getDoc(doc(db, Collections.Links, tagID))

            const linkExists = queryLink.exists()
            

            if (!linkExists) {
                return "false"
            }
            tagID = queryLink.data().tagID
            const querySecond = await getDoc(doc(db, Collections.ReportableTagIDs, tagID))
            const tagExistsSecond = querySecond.exists()
            if (tagExistsSecond) {
                return tagID
            }
            return "false"
        }
        const time = Date.now()
        const data = { scanned: { [time]: time } }
        // await setDoc(doc(db, 'reportableTagId', tagID),data,{ merge: true })



        return tagID
        
    }

    public static async sendReport(data: { tagID: string, fields: ReportField[] }): Promise<HttpsCallableResult> {
        const result = await fileReport({
            tagID: data.tagID,
            fields: data.fields,
        })
        return result
    }


    public static async sendReportTemplate(tagID: string, templateID: string): Promise<FunctionsErrorCode> {
        try {
            await fileReportFromTemplate({
                tagID: tagID,
                templateID: templateID
            })
            return 'functions/ok'
        }
        catch (e) {
            const err = (e as FunctionsError)
            const code = err.code
            console.error(err.message, err.cause, err.details)
            return code
        }
    }


    public static async createReportTemplate(props: { fields: ReportField[], reportCode: string, createAsUserID: string }): Promise<FunctionsErrorCode> {
        try {
            await createReportTemplate({
                fields: props.fields,
                displayName: props.reportCode,
                reportCode: props.reportCode,
                createAsUserID: props.createAsUserID
            })
            return 'functions/ok'
        }
        catch (e) {
            const err = (e as FunctionsError)
            const code = err.code
            console.error(err.message, err.cause, err.details)
            return code
        }
    }

    public static async canCreateReportTemplate(userID: string): Promise<FunctionsErrorCode> {
        try {
            await canCreateReportTemplate({ createAsUserID: userID })
            return 'functions/ok'
        }
        catch (e) {
            const code = (e as FunctionsError).code
            return code
        }
    }
}


