import React from 'react'





export default function PhoneNumberInput(props: { onValue: (value: string) => void , defaultValue?:string}) {
    return (
        <input 
            onChange={(event) => props.onValue(event.target.value)} 
            placeholder='someone@something.com'
            defaultValue={props.defaultValue}
        />
    )
}