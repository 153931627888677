import React, { useEffect, useRef, useState } from 'react'
import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { isLatLngLiteral } from '@googlemaps/typescript-guards'
import { deepEqual } from 'fast-equals'
import { createSearchParams, useLoaderData, useNavigate } from 'react-router-dom'
import axios from 'axios'
import BigButton from '../big-button'
import FormBase from '../reporting/form-base'
import PhoneNumberInput from '../phone-number-input'
import { ReportFieldBase, ExactLocation, Message, ContactInformation } from '../../backend/databaseTypes'
import { Reports } from '../../backend/reports'
import { useCreateTemplateReportOutletContext } from '../../routes/report-template-root'
import { logEvent } from 'firebase/analytics'
import { analytics, analyticsDebug } from '../../backend/firebaseConfig'


export async function loader() {
    try {
        const ipLocationResponse = await axios.get('https://geolocation-db.com/json/')
        return { lat: ipLocationResponse.data.latitude, lng: ipLocationResponse.data.longitude }
    } catch (e) {
        console.log('Failed to get ip location')
        return null
    }
}

function validateEmail(email: string) {
    if (!email) {
        return false
    }
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export default function CreateReportTemplate() {

    const ipLocation = useLoaderData()
    const context = useCreateTemplateReportOutletContext()
    const navigate = useNavigate()
    const [descripton, setDescription] = useState('')
    const [contactInfo, setContactInfo] = useState('')
    const [reportName, setRawReportName] = useState('')
    const [map, setMap] = useState<google.maps.Map>()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    
    const [defaultLocation, setDefaultLocation] = useState({
        lat: 38.648785,
        lng: -90.310729
    })

    async function submit() {
            
        const fields: ReportFieldBase[] = []
        
        if (map) {
            const location = map.getCenter()
            if (location) {
                fields.push(new ExactLocation(location.lat(), location.lng()))
            }
        }

        if (descripton) {
            fields.push(new Message(descripton))
        }

        if (contactInfo.length !== 0) {
            fields.push(new ContactInformation(contactInfo))
        }

        const report = {
            displayName: reportName,
            reportCode: reportName,
            fields: Object.fromEntries(fields.map((field) => {
                const obj = field.toPlainObject()
                return [obj.type, obj]
            })),
            createAsUserID: context.createAsUserID
        }

        console.log(report)

        setLoading(true)
        const result = await Reports.createReportTemplate(report)
        setLoading(false)

        if (result === 'functions/ok') {
            navigate({ pathname: 'template-created', search: `?code=${report.reportCode}` })
            return
        }

        switch (result) {
            case 'functions/invalid-argument':
                setErrorMessage(`There's something wrong with the data you entered. Please check each field and try again.`)
                break
            case 'functions/already-exists':
                setErrorMessage(`That pre-filled report code is already taken. Please try something different.`)
                break
            case 'functions/permission-denied':
                setErrorMessage(`There's something wrong with your link. Please check your link and try again.`)
                break
            default:
                setErrorMessage(`Something went wrong. Please try again.`)
        }
    }

    useEffect(() => {
        if (ipLocation && isLatLngLiteral(ipLocation)) {
            setDefaultLocation(ipLocation)
        }
    }, [ipLocation])

    const render = (status: Status) => {
        switch (status) {
          case Status.LOADING:
            return <p>Loading...</p>
          case Status.FAILURE:
            return <p>{`Failed to load google maps :(`}</p>
          case Status.SUCCESS:
            return (
                <LocationInputMap 
                    center={defaultLocation} 
                    zoom={12} 
                    streetViewControl={false} 
                    fullscreenControl={false} 
                    mapTypeControl={false} 
                    setMapProp={setMap}
                    onSubmit={(newLocation) => {
                        }
                    } 
                />
            )
        }
    }

    function setReportName(name: string) {
        const noWhitespaceName = name.replaceAll(/\s+/g, '')
        const lowerCaseName = noWhitespaceName.toLocaleLowerCase()
        setRawReportName(lowerCaseName)
    }

    const disableSubmit = !validateEmail(contactInfo) ||
        contactInfo.length === 0 ||
        loading || 
        reportName.length === 0 || 
        descripton.length === 0 ||
        !map?.getCenter();
    logEvent(analytics, 'how to', {
        'debug_mode':analyticsDebug
        });
    return (
        <FormBase>
            <h1>New Pre-Filled Report</h1>
            <p className='margin-top-16'>To make it easier for your team members to report items, you can create a pre-filled report here. Then, all your team member has to do to report a Beacon Tag is enter this report's code. For assistance, please <a href='mailto:support@beacontags.com' className='link'>contact support</a>.</p>
            <h2 className='black margin-top-32'>Where will the item be?</h2>
            <p className='margin-top-8 margin-bottom-8'>This is the location owners will see on their map. Click on the crosshairs to locate yourself.</p>
            <Wrapper apiKey={'AIzaSyBCdOph1FqRHjuTRwy3P0zp4-zoiqeZYz8'} render={render} />
            <h2 className='margin-top-32 black'>How can the owner get their item back?</h2>
            <p className='margin-top-8 margin-bottom-8'>Ex. “Visit the lost and found in Bauer Hall. We are on the 4th floor. Our hours are...”</p>
            <textarea 
                maxLength={1000} 
                autoFocus={true}
                onInput={(event) => setDescription((event.target as any).value)}
            />
            <h2 className='margin-top-32 black'>How can owners get in touch with you?</h2>
            <p className='margin-top-8 margin-bottom-8'>Owners will be able to contact you via this email if they have any questions.</p>
            <PhoneNumberInput onValue={setContactInfo} />
            <h2 className='margin-top-32 black'>What is this report called?</h2>
            <p className='margin-top-8 margin-bottom-8'>This is what your team members will type in to send this pre-filled report, like "washu-duc-first-floor". Codes must be unique and lower case, and they can't contain spaces.</p>
            <input
                onChange={(event) => setReportName(event.target.value)}
                placeholder="Code"
                value={reportName}
                autoCapitalize='none'
                autoComplete='off'
            />
            {errorMessage ? <p className='margin-top-16 color-red'>{errorMessage}</p> : null}
            <BigButton loading={loading} label='Create Report' onClick={() => submit()} disabled={disableSubmit} style={{ marginTop: 32 }} />

        </FormBase>
    )
}


interface MapProps extends google.maps.MapOptions {
    onSubmit: (location: google.maps.LatLng | undefined) => void
    setMapProp?: (map: google.maps.Map) =>void
    style?: { [key: string]: string }
    children?: React.ReactNode
}
  
const LocationInputMap: React.FC<MapProps> = ({
    onSubmit,
    children,
    style,
    setMapProp,
    ...options
}: MapProps) => {

    const ref = useRef<HTMLDivElement>(null)
    const [map, setMap] = useState<google.maps.Map>()
    
    useEffect(() => {
        if (ref.current && ! map) {
            const newMap = new window.google.maps.Map(ref.current, { })
            if(setMapProp != undefined){
            setMapProp(newMap)
            }
            setMap(newMap)
            // newMap.getBounds().
        }
    }, [ref, map])

    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options)
        }
    }, [map, options])

    const moveToUserLocation = (location: GeolocationPosition | null) => {
        if ( ! location) { return }
        map?.panTo({ lat: location.coords.latitude, lng: location.coords.longitude })
    }

    return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
            <div style={{ position: 'relative' }}>
                <div className='map-container' ref={ref} id='map'>
                    {
                        React.Children.map(children, (child) => {
                            if (React.isValidElement(child)) {
                                // set the map prop on the child component
                                // @ts-ignore
                                return React.cloneElement(child, { map });
                            }
                        })
                    }
                </div>
                <div 
                    id='my-location-container' 
                    onClick={() => getLocation().then(loc => moveToUserLocation(loc.position))}
                    title="Locate me"
                >
                    <span className="material-symbols-outlined">
                        my_location
                    </span>
                </div>
                <div className='vertical-line' />
                <div className='horizontal-line' />
                <div className='dot' />
            </div>
            
        </div>
    )
}

const getLocation = async (): Promise<{ position: GeolocationPosition | null, error: GeolocationPositionError | null }> => {
    return new Promise((resolve, _reject) => {
        navigator.geolocation.getCurrentPosition((position) => {
            resolve({ position, error: null })
        }, (error) => {
            resolve({ position: null, error })
        }, {
            enableHighAccuracy: false,
            maximumAge: 5000,
            timeout: 5000
        })
    })
}

const deepCompareEqualsForMaps = (a: any, b: any) => {
    if (
        isLatLngLiteral(a) ||
        a instanceof google.maps.LatLng ||
        isLatLngLiteral(b) ||
        b instanceof google.maps.LatLng
    ) {
        return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }
    
    return deepEqual(a, b);
}


function useDeepCompareMemoize(value: any) {
    const ref = React.useRef();

    if ( ! deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

function useDeepCompareEffectForMaps(callback: React.EffectCallback, dependencies: any[]) {
    React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}