import { Link, Outlet } from 'react-router-dom';
import '../styles/root.css'
import ProductHomepage from '../components/product-homepage';
import Logo from '../components/logo';
import Footer from '../components/footer';
import { analytics, analyticsDebug } from '../backend/firebaseConfig';
import { logEvent } from 'firebase/analytics';

export default function Root() {
    logEvent(analytics, 'view_homepage', {
        'debug_mode':analyticsDebug
      });
    return (
        <>
            <ProductHomepage />
            <Footer />
        </>
    )
}