import React from 'react';
import MinNavbar from '../components/min-navbar';
import FormBase from '../components/reporting/form-base';
import { Link } from 'react-router-dom';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import PageContainer from '../components/page-container';
import { logEvent } from 'firebase/analytics';
import { analytics, analyticsDebug } from '../backend/firebaseConfig';

export default function Store() {
    logEvent(analytics, 'view_store', {
        'debug_mode':analyticsDebug
      });
    return (
        <PageContainer>
            <Navbar />
            <FormBase>
                <h1 style={{ textAlign: 'center' }} className='margin-bottom-16'>Our Products</h1>
                {/* <p className='margin-top-32 margin-bottom-16'>Thanks for your interest in Beacon Tags! We're not quite ready to start shipping, but if you would like to get notified when we are, join one of our mailing lists:</p> 
                <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4igySGNfaGQRx1Rx3Fz123AcVPg4f5apzCB6OxObH6yEnRQ/viewform?usp=sf_link' className='margin-bottom-8 link' target='_blank'>For people →</a>
                <a href='https://docs.google.com/forms/d/e/1FAIpQLScv4Y3HW1fNES4roTmevqZ2_oWn-cqHTinUdk8RZJDzyyyJMQ/viewform?usp=sf_link' className='link' target='_blank'>For institutions →</a> */}
                <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
                <stripe-pricing-table pricing-table-id="prctbl_1NaUhhJIveV3eOGqrVC6CplB"
                publishable-key="pk_live_51MPs5CJIveV3eOGqNPtOmFXw9oYeoBDSv6GlYziKI7Wzf8ShAU8k8VD9lxWdTQjHiAkimLI7q9nOOGQ1uBAReFJa003l6pdI4u">
                </stripe-pricing-table>
            </FormBase>
        </PageContainer>
    )
}