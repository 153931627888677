import '../styles/root.css'
import '../styles/footer.css'
import DownloadAppLink from "./download-app-link";
import { Link } from 'react-router-dom';
import { Links } from '../backend/links';

export default function Footer() {
    return (
        <div className='footer-container'>
            <div className='footer-col-container'>
                <div className='footer-col'>
                    <h3 className='col-header'>Get the App</h3>
                    <DownloadAppLink store='apple' />
                    <DownloadAppLink store='google' />
                </div>
                <div className='footer-col'>
                    <h3 className='col-header'>Legal</h3>
                    <a href={Links.TERMS_OF_SERVICE} className='col-text link' target='_blank'>Terms of Service</a>
                    <a href={Links.PRIVACY_POLICY} className='col-text link' target='_blank'>Privacy Policy</a>
                </div>
                <div className='footer-col'>
                    <h3 className='col-header'>Get Help</h3>
                    <a href={'/how-to'} className='col-text link'>Getting Started</a>
                    <a href={'/demo'} className='col-text link'>View a Demo</a>
                    <a href={'mailto:support@beacontags.com'} className='col-text link'>Email Support</a>
                    <a href={'tel:+12158398162'} className='col-text link'>Call Support</a>
                    <a href={'sms:+12158398162'} className='col-text link'>Text Support</a>
                </div>
            </div>
            <div className='footer-footer-container'>
                <p className='quiet col-text'>© 2023 Gobi Labs, LLC</p>
            </div>
        </div>
    )
}