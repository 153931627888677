import React from "react";

export default function YoutubeEmbed(props:{ embedId:string }) {
return(
  <div className="video-responsive">
    <iframe
      height={300} 
      width={'100%'}
      src={`https://www.youtube.com/embed/${props.embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
        )
}



