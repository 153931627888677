import { logEvent } from "firebase/analytics"
import React from "react"
import { Link } from "react-router-dom"
import { analytics, analyticsDebug } from "../backend/firebaseConfig"

export default function CallToActionButton() {
    return (
        <Link className='call-to-action-button' to={'/store'} onClick={()=>{
            logEvent(analytics, 'call to action button pressed', {
                'debug_mode':analyticsDebug
              })
        }}>
            <h2 style={{ fontWeight: 'bold' }}>Buy now</h2>
        </Link>
    )
}