import React from 'react'

export default function FormBase(props: { centerContent?: boolean, children?: React.ReactNode, after?: React.ReactNode }) {
    return (
        <div className='form-centering-container'>
            <div className={'form-container panel' + (props.centerContent ? ' center' : '')}>
                {props.children}
            </div>
            <div className='form-container' style={{ paddingTop: 16, paddingRight: 0, paddingLeft: 0 }}>
                {props.after}
            </div>
        </div>
    )
}