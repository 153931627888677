import { getAuth, signInAnonymously } from 'firebase/auth';

export default function trySignIn() {
    if (getAuth().currentUser == null) {
        console.log('signing in...')
        return signInAnonymously(getAuth())
            .then((user) => {
                console.log('signed in.')
                return user.user.uid
            })
            .catch((reason) => {
                console.error(`Anonymous sign-in failed; ${reason}`)
                return reason
            })
    }

    return null
}