import '../styles/product.css'
import '../styles/root.css'

export default function HowToStep(props: { index: number, text: string, content: React.ReactNode }) {
    return (
        <div className='step-container'>
            <div className='step-image-container'>
                {props.content}
            </div>
            <div className='centered-expanding-row how-to-width margin-top-32'>
                <h1 className='hero margin-right-16'>{props.index}.</h1>
                <h2 className='black normal'>{props.text}</h2>
            </div>
        </div>
    )
}

export function HowToImage(props: { index: number, imageHeight: number }) {
    return <img src={`step-${props.index}.jpg`} height={props.imageHeight} className='step-image'/>;
}

export function HowToReport() {
    return (
        <div className='report-container'>
            <h4 className='black'>Headset Spotted • Walnut and 18th Street</h4>
            <h4 className='black normal margin-top-16'>"I left it with the barista at the cafe on the corner."</h4>
        </div>
    )
}