import React, { Dispatch, useState } from 'react'
import { Link, Outlet, useLoaderData, useOutletContext } from 'react-router-dom'
import { Reports } from '../backend/reports'
import '../styles/report.css'
import trySignIn from '../backend/signIn';
import FormBase from '../components/reporting/form-base';
import BigButton from '../components/big-button';
import Navbar from '../components/navbar';
import SmallButton from '../components/small-button';
import YoutubeEmbed from '../components/youtube-video';
import { Links } from '../backend/links';
import DownloadAppLink from '../components/download-app-link';
import { error } from 'console';
import { logEvent } from 'firebase/analytics';
import { analytics, analyticsDebug } from '../backend/firebaseConfig';

export async function loader({ params }: any) {

    await trySignIn()

    if (!params.tagID) {
        console.error('bad url')
        return false
    }

    return await Reports.canReportID(params.tagID)
    
}

type ContextType = { tagID: string, location: { lat: number, lng: number }, setLocation: Dispatch<{ lat: number, lng: number }>, descripton: string, setDescripton: Dispatch<string>, contactInfo: string, setContactInfo: Dispatch<string> }
export default function SubmitReport() {

    const tagID = useLoaderData() 
    
        
    const [location, setLocation] = useState({} as Location)

    const [descripton, setDescripton] = useState('')
    const [contactInfo, setContactInfo] = useState('')

    return (
        <>
            <Navbar />
            {
                tagID != "false" ?
                    <Outlet context={{ tagID:tagID,location: location, setLocation: setLocation, descripton: descripton, setDescripton: setDescripton, contactInfo: contactInfo, setContactInfo: setContactInfo }} /> :
                    <InvalidItem />
            }
        </>
    )
}

function InvalidItem() {
    logEvent(analytics, 'invalid_item', {
        'debug_mode':analyticsDebug
      });
    return (
        <FormBase>
            <h1>Hmm...</h1>
            <p className='margin-top-16'>This tag doesn't exist or isn't associated with an item yet. If you're the owner of this tag and would like to register it click below</p>

            <BigButton label={"How To"} linksTo='/how-to' style={{ marginTop: 32, marginBottom: 16 }} />
                    
        </FormBase>
    )
}

export function Intro() {
    const context = useContext();
    if (context.contactInfo != '') {
        context.setContactInfo('')
    }
    if (context.descripton != '') {
        context.setDescripton('')
    }
    if (context.location.lat != undefined) {
        context.setLocation({} as { lat: number, lng: number })
    }
    logEvent(analytics, 'reporting_item', {
        'debug_mode':analyticsDebug
      });
    return (
        <FormBase after={<p className='quiet center'>By continuing, you agree to our <a href={Links.TERMS_OF_SERVICE} className='link' target='_blank'>Terms of Use</a> and <a href={Links.PRIVACY_POLICY} className='link' target='_blank'>Privacy Policy</a>.</p>}>
            <h1>Someone lost this!</h1>
            <p className='large margin-top-64'>They'd really appreciate your help getting it back. It'll only take a sec, and any information helps.</p>
            <BigButton label={`I'll Help! →`} linksTo='location' style={{ marginTop: 32, marginBottom: 16 }} />
            <p className='quiet' style={{ textAlign: 'center' }}>Or <Link to={'report-template'} className='link'>use an pre-filled report code</Link></p>
        </FormBase>
    )
}

export function useContext() {
    return useOutletContext<ContextType>();
}

