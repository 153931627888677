
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FadeLoader, PulseLoader } from 'react-spinners'


export default function BackButton(props:{linksTo?:string}) {    


    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    return (
        
        <div className='back-button margin-bottom-16' onClick={()=>{
            setLoading(true)
            props.linksTo?navigate(props.linksTo):navigate(-1)}}  style={{ cursor: 'pointer', pointerEvents: (loading ? 'none' : 'auto')}}>
                {loading? <><PulseLoader size={5} color="#FFFFFF" /> </> :<><h4 style={{ userSelect: 'none', WebkitUserSelect: 'none' }}> {"←"} </h4> </>}
        </div>
        
    )
}



