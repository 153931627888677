import React, { useEffect, useRef, useState } from 'react'
import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { isLatLngLiteral } from '@googlemaps/typescript-guards'
import { deepEqual } from 'fast-equals'
import { createSearchParams, useLoaderData, useNavigate } from 'react-router-dom'
import axios from 'axios'
import FormBase from './form-base'
import { MapStyles } from '../../map-config'
import BigButton from '../big-button'
import BackButton from '../back-button'
import { useContext } from '../../routes/report'
import StepIndicator from '../step-indicator'
import { logEvent } from 'firebase/analytics'
import { analytics, analyticsDebug } from '../../backend/firebaseConfig'

const DEFAULT_LOCATION: google.maps.LatLngLiteral = { lat: 38.6529844, lng: -90.4086118 }

export async function loader({ params }: any): Promise<{ ipLocationResponse: any }> {

    try {
        const ipLocationResponse = await axios.get('https://geolocation-db.com/json/', {timeout: 250})
        return { ipLocationResponse: { lat: ipLocationResponse.data.latitude, lng: ipLocationResponse.data.longitude } }
    } catch (e) {
        console.log('Failed to get ip location')
        return { ipLocationResponse: null }
    }
}

export default function InputLocation() {
    logEvent(analytics, 'input location', {
        'debug_mode':analyticsDebug
      });
    const loaderData = useLoaderData() as { ipLocationResponse: any, tagID: string }
    const ipLocation = loaderData.ipLocationResponse
    
    const navigate = useNavigate()
    const context = useContext()
    const tagID = context.tagID
    useEffect(() => {
        if (context.location.lat == undefined && ipLocation && isLatLngLiteral(ipLocation)) {
            context.setLocation(ipLocation)
        }
    }, [ipLocation])

    console.error(context)
    if(context.location.lat == undefined){
        navigate(("/item/"+tagID))
    }
    useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        return () => {
          window.removeEventListener("beforeunload", alertUser);
        };
      }, []);
      const alertUser = (e:any) => {
        e.preventDefault();
        e.returnValue = "";
      };

    const mapCenter = isLatLngLiteral(context.location) ? context.location : DEFAULT_LOCATION
    const mapZoom = isLatLngLiteral(context.location) ? 12 : 3.5

    const render = (status: Status) => {
        switch (status) {
            case Status.LOADING:
                return <p>Loading...</p>
            case Status.FAILURE:
                return <p>{`Failed to load google maps :(`}</p>
            case Status.SUCCESS:
                return (
                    <LocationInputMap
                        center={mapCenter}
                        zoom={mapZoom}
                        streetViewControl={false}
                        fullscreenControl={false}
                        mapTypeControl={false}
                        onSubmit={(newLocation) => {
                            if (newLocation) {
                                submit({ lat: newLocation.lat(), lng: newLocation.lng() })
                            }
                        }}
                    />
                )
        }
    }

    const submit = ({ lat, lng }: { lat: number, lng: number }) => {
        context.setLocation({ lat: lat, lng: lng })
        navigate({
            pathname: `../description`,
        })
    }

    return (
        <FormBase>
            <StepIndicator link={`/item/${tagID}`} index={1} canGoBack />
            <h1>Great!</h1>
            <p className='margin-top-16 margin-bottom-16'>Can you show us where you spotted the item? Click the crosshairs button in the top right to locate yourself.</p>
            <Wrapper apiKey={'AIzaSyBCdOph1FqRHjuTRwy3P0zp4-zoiqeZYz8'} render={render} />
        </FormBase>
    )
}

interface MapProps extends google.maps.MapOptions {
    onSubmit: (location: google.maps.LatLng | undefined) => void
    style?: { [key: string]: string }
    children?: React.ReactNode
}

const LocationInputMap: React.FC<MapProps> = ({
    onSubmit,
    children,
    style,
    ...options
}: MapProps) => {

    const ref = useRef<HTMLDivElement>(null)
    const [map, setMap] = useState<google.maps.Map>()

    useEffect(() => {
        if (ref.current && !map) {
            const newMap = new window.google.maps.Map(ref.current, {})
            setMap(newMap)
            // newMap.getBounds().
        }
    }, [ref, map])

    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options)
        }
    }, [map, options])

    const moveToUserLocation = (location: GeolocationPosition | null) => {
        if (!location) { return }
        map?.panTo({ lat: location.coords.latitude, lng: location.coords.longitude })
        map?.setZoom(Math.max(map?.getZoom() ?? 0, 12))
    }

    return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
            <div style={{ position: 'relative' }}>
                <div className='map-container' ref={ref} id='map'>
                    {
                        React.Children.map(children, (child) => {
                            if (React.isValidElement(child)) {
                                // set the map prop on the child component
                                // @ts-ignore
                                return React.cloneElement(child, { map });
                            }
                        })
                    }
                </div>
                <div
                    id='my-location-container'
                    onClick={() => getLocation().then(loc => moveToUserLocation(loc.position))}
                    title="Locate me"
                >
                    <span className="material-symbols-outlined">
                        my_location
                    </span>
                </div>
                <div className='vertical-line' />
                <div className='horizontal-line' />
                <div className='dot' />
            </div>
            <BigButton label='Next →' onClick={() => onSubmit(map?.getCenter())} style={{ marginTop: 32 }} />
        </div>
    )
}

const getLocation = async (): Promise<{ position: GeolocationPosition | null, error: GeolocationPositionError | null }> => {
    return new Promise((resolve, _reject) => {
        navigator.geolocation.getCurrentPosition((position) => {
            resolve({ position, error: null })
        }, (error) => {
            console.error(error)
            resolve({ position: null, error })
        }, {
            enableHighAccuracy: false,
            maximumAge: 1000,
            timeout: 7000
        })
    })
}

const deepCompareEqualsForMaps = (a: any, b: any) => {
    if (
        isLatLngLiteral(a) ||
        a instanceof google.maps.LatLng ||
        isLatLngLiteral(b) ||
        b instanceof google.maps.LatLng
    ) {
        return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    return deepEqual(a, b);
}


function useDeepCompareMemoize(value: any) {
    const ref = React.useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

function useDeepCompareEffectForMaps(callback: React.EffectCallback, dependencies: any[]) {
    React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}