import React from 'react'

import '../styles/root.css'
import FormBase from '../components/reporting/form-base'
import YoutubeEmbed from '../components/youtube-video'
import PageContainer from '../components/page-container'
import Navbar from '../components/navbar'
import DownloadAppLink from '../components/download-app-link'

export default function Demo(props: any) {
    return (
        <PageContainer>
            <Navbar />
            <FormBase>
                <h1>Demos</h1>
                <div className='margin-top-64' />
                <p>Want to see Beacon Tags in action? You're in the right spot.</p>
                
                <h4 className='black margin-top-32' >Product Demo</h4>
                <div className='margin-top-8' />
                <YoutubeEmbed embedId='wShoWUgo58c' />
                <div className='margin-bottom-16' />


                <h4 className='black' >App Demo</h4>
                <div className='margin-top-8' />
                <YoutubeEmbed embedId='h5MlZigjDGk' />
                
            </FormBase>
        </PageContainer>
    )
}


