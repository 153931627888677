import React, { useEffect } from 'react'

import '../styles/root.css'
import FormBase from '../components/reporting/form-base'
import YoutubeEmbed from '../components/youtube-video'
import PageContainer from '../components/page-container'
import Navbar from '../components/navbar'
import DownloadAppLink from '../components/download-app-link'
import { useLocation } from 'react-router-dom'
import { logEvent } from 'firebase/analytics'
import { analytics, analyticsDebug } from '../backend/firebaseConfig'

export default function HowTo(props: any) {

    logEvent(analytics, 'how to', {
        'debug_mode':analyticsDebug
      });
    return (
        <PageContainer>
            <Navbar />
            <FormBase>
                <h1>Getting Started</h1>
                <div className='margin-top-64' />
                
                <div style={{ display: 'grid', gridTemplateColumns: 'max-content max-content', gridTemplateRows: 'repeat(8, max-content)', columnGap: 12, overflow: 'hidden', width: '100vw' }}>
                    <h3 style={{ gridColumn: '1', gridRow: '1' }}>1.</h3>
                    <h3 style={{ gridColumn: '1', gridRow: '3' }}>2.</h3>
                    <h3 style={{ gridColumn: '1', gridRow: '5' }}>3.</h3>
                    <h3 style={{ gridColumn: '1', gridRow: '7' }}>4.</h3>
                    <h3 className='margin-bottom-32 normal' style={{ gridColumn: '2', gridRow: '1' }}>Download our app and log in</h3>
                    <h3 className='margin-bottom-32 normal' style={{ gridColumn: '2', gridRow: '3' }}>Stick your Beacon Tag to your item</h3>
                    <h3 className='margin-bottom-32 normal' style={{ gridColumn: '2', gridRow: '5' }}>Click "Add Item" in our app</h3>
                    <h3 className='margin-bottom-32 normal' style={{ gridColumn: '2', gridRow: '7' }}>Scan your tag to add it as an item</h3>

                    <div style={{ gridColumn: '2', gridRow: '2' }} className='margin-bottom-48'>
                        <DownloadAppLink store='apple'/> <DownloadAppLink store='google'/>
                    </div>
                    <img style={{ gridColumn: '2', gridRow: '4' }} src='how-to-2.png' className='margin-bottom-48' height={200}/>
                    <div className='margin-bottom-48' style={{ backgroundColor: '#151530', padding: 16, paddingLeft: 32, paddingRight: 32, borderRadius: 10, textAlign: 'center', gridColumn: '2', gridRow: '6' }}>
                        <h5>Add Item</h5>
                    </div>
                    <img style={{ gridColumn: '2', gridRow: '8' }} src='how-to-4.png' height={200}/>
                </div>
                
            </FormBase>
        </PageContainer>
    )
}


