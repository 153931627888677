import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { firebaseConfig } from './configFile'
import { getAnalytics } from 'firebase/analytics'



export const app = initializeApp(firebaseConfig)

export const EMULATED = false

export const db = getFirestore(app)
export const functions = getFunctions(app)
export const auth = getAuth(app)
export const analytics = getAnalytics(app)
export const analyticsDebug = false
if (EMULATED) {
    connectFirestoreEmulator(db, 'localhost', 8080)
    connectAuthEmulator(auth, 'http://localhost:9099')
    connectFunctionsEmulator(functions, 'localhost', 5001)
}