import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import './styles/root.css';
import 'material-symbols';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import Root from './routes/root';
import ErrorPage from './error-page';
import Store from './routes/store';
import SubmitReport, {
    Intro,
    loader as tagValidator
} from './routes/report';
import InputLocation, { loader as ipLocationLoader } from './components/reporting/input-location';
import InputDescription, { loader as idLoader } from './components/reporting/input-description';
import ThankYou from './components/reporting/thank-you';
import ContactInfo from './components/reporting/input-contact-info';
import SendReportTemplate from './components/reporting/send-report-template';
import CreateReportTemplate from './components/admin/createReport';
import TemplateCreated from './components/admin/template-added';
import ReportTemplateRoot, { loader as createReportTemplateLoader } from './routes/report-template-root';
import HowTo from './routes/how-to';
import Demo from './routes/demo';

const routerReport = createBrowserRouter([
    {
        path: '/',
        element: <Root/>,
        errorElement: <ErrorPage />,
    },
    {
        path: '/item/:tagID',
        element: <SubmitReport />,
        errorElement: <ErrorPage />,
        loader: tagValidator,
        children: [
            {
                path: '',
                element: <Intro />
            },
            {
                loader: idLoader,
                path: 'report-template',
                element: <SendReportTemplate />
            },
            {
                loader: ipLocationLoader,
                path: 'location',
                element: <InputLocation />
            },
            {
                loader: idLoader,
                path: 'description',
                element: <InputDescription />
            },
            {
                loader: idLoader,
                path: 'contactInfo',
                element: <ContactInfo />
            },
            {
                loader: idLoader,
                path: 'thank-you',
                element: <ThankYou />
            }
        ]
    },
    {
        path: '/store',
        element: <Store />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/view-demo',
        element: <Demo />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/how-to',
        element: <HowTo />,
        errorElement: <ErrorPage />,
    },
    {

        path: '/report-template/:userID',
        element: <ReportTemplateRoot />,
        errorElement: <ErrorPage />,
        loader: createReportTemplateLoader,
        children: [
            {
                path: '',
                element: <CreateReportTemplate />
            },
            {
                path: 'template-created',
                element: <TemplateCreated />
            }
        ]
    }
]);


const routerHome = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/store',
        element: <Store />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/demo',
        element: <Demo />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/how-to',
        element: <HowTo />,
        errorElement: <ErrorPage />,
    }
]);


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
const report = window.location.host.split('.')[0] === 'report' || window.location.host.includes("localhost")
root.render(
    <React.StrictMode>
        {report?
        <RouterProvider router={routerReport} />:
        <RouterProvider router={routerHome} />}
    </React.StrictMode>
)
