import React, { useEffect } from 'react'
import { Link, redirect, useLoaderData, useNavigate } from 'react-router-dom'
import BigButton from '../big-button'
import FormBase from './form-base'
import BackButton from '../back-button'
import { logEvent } from 'firebase/analytics'
import { analytics, analyticsDebug } from '../../backend/firebaseConfig'


export default function ThankYou() {
    const tagID = useLoaderData() as string
    const navigate = useNavigate()
    const BackButtonListener = ({ children }:any) => {
        React.useEffect(() => {
          window.onpopstate = e => {
            navigate(("/item/"+tagID))
          };
        });
       return (
          null
        );
      };

      logEvent(analytics, 'thank you page', {
        'debug_mode':analyticsDebug
      });
    return (
        <FormBase>
            <BackButtonListener />
            <h1>That's it!</h1>
            <p className='margin-top-16'>Thank you so much for your help.</p>
            <p className='margin-top-8'>If you want to help your lost things find their way home, check out...</p>
            <BigButton linksTo='/store' label='Our Store' style={{ marginTop: '32px' }}/>
        </FormBase>
    )
}