import React from 'react'
import { Link, useNavigate, useNavigation, useSearchParams } from 'react-router-dom'
import BigButton from '../big-button'
import FormBase from '../reporting/form-base'

export default function TemplateCreated() {

    const [urlParams, _setURLParams] = useSearchParams()
    const code = urlParams.get('code')

    return (
        <FormBase>
            <h1>Nicely done!</h1>
            <p className='margin-top-16'>Your pre-filled report has been created. Remember to save its code:</p>
            <p className='margin-top-8'><b>{code}</b></p>
            <BigButton linksTo='../' label='Create Another' style={{ marginTop: '32px' }}/>
        </FormBase>
    )
}