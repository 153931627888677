import { Link } from 'react-router-dom'
import '../styles/product.css'
import '../styles/root.css'
import CallToActionButton from './call-to-action-button'
import HowToStep, { HowToImage, HowToReport } from './how-to-step'
import Logo from './logo'
import MinNavbar from './min-navbar'
import { logEvent } from 'firebase/analytics'
import { analytics, analyticsDebug } from '../backend/firebaseConfig'
import { useEffect, useRef, useState } from 'react'
export default function ProductHomepage() {
    const howToRef = useRef<HTMLInputElement>(null)
    const whyBeaconRef = useRef<HTMLInputElement>(null)

    let  heightOfHowTo = 100000 
    let  heightOfWhyBeacon = 100000 
    

    let howToReported = false
    let whyBeaconReported = false
    
    
    
    useEffect(() => {
        const elementHowTo = howToRef.current;
        const elementWhyBeacon = whyBeaconRef.current;

        if(elementHowTo != null){
            
            const howToPosition = elementHowTo.getBoundingClientRect();
            console.log("how to",howToPosition.top)
            heightOfHowTo = (howToPosition.top);}

        if(elementWhyBeacon != null){
            const heightOfWhyBeaconPosition = elementWhyBeacon.getBoundingClientRect()
            console.log("why beacon",heightOfWhyBeaconPosition.top)
            heightOfWhyBeacon = (heightOfWhyBeaconPosition.top)}
       
        
    }, [howToRef, whyBeaconRef]);
   
    

    useEffect(() => {
        window.addEventListener('scroll', pop);
        return () => window.removeEventListener('scroll', pop);
      },[]);

    const pop = () => {
        
    if (heightOfWhyBeacon && window.scrollY > heightOfWhyBeacon && !whyBeaconReported) {
        console.warn("scroll to why beacon", whyBeaconReported)
        whyBeaconReported = true
        console.warn("scroll to why beacon2", whyBeaconReported)
        logEvent(analytics, 'scorlled to why beacon', {
            'debug_mode':analyticsDebug
          })
        
    }

    if (heightOfHowTo && window.scrollY > heightOfHowTo && !howToReported) {
        console.warn("scroll to how it works", howToReported)
        howToReported = true
        console.warn("scroll to how it works2", howToReported)
        logEvent(analytics, 'scorlled to how it works', {
            'debug_mode':analyticsDebug
          })
    }
                
    
        
}
      

    return (
        <>
        
            <div className='wide-col'>
                <div className='intro-container'>
                    <MinNavbar />
                    <div className='hero-image-container '>
                        <img src='splash.jpg' height='1284' className='hero-image'/>
                    </div>
                    <div className='left-col offset'>
                        <h1 className='hero'>Stop losing your stuff.</h1>
                        <h2 className='margin-top-24 margin-bottom-24 black normal' id='tagline'><b>Beacon Tags</b> make it easy for others to help you when you've lost something, so we can give you the information you need to reclaim your stuff.</h2>
                        <div className='centered-expanding-row margin-top-8' style={{ rowGap: 8 }} id='call-to-action-container'>
                            <CallToActionButton />
                            <h2 className='black' id='learn-more-text'>or learn more &nbsp;↓</h2>
                        </div>
                    </div>
                </div>
                <div className='banner'>
                    <h1 className='' ref={whyBeaconRef}><b>Why Beacon?</b></h1>
                    <div className='pro-container'>
                        <h2 className='margin-top-24 margin-bottom-16 black normal' id='tagline'>Great question. Beacon Tags are...</h2>
                        <h4 className='black normal margin-bottom-8'><b>Effective. </b>&gt;75% of items are scanned & reported within 3 days</h4>
                        <h4 className='black normal margin-bottom-8'><b>Secure. </b>Unlike handwritten tags, your identity is not revealed</h4>
                        <h4 className='black normal margin-bottom-24'><b>Afforable. </b>Tags start at $1—so you can put them on anything</h4>
                    </div>
                </div>
                
                <div className='clear-banner'>
                    <h1  className='' ref={howToRef}><b>How it Works</b></h1>
                </div>

                <div  className='process-container'>
                    <HowToStep index={1} content={<HowToImage imageHeight={648} index={1}/>} text="Got something you don't want to lose? Stick on a tag & activate it in our app."/>
                    <HowToStep index={2} content={<HowToImage imageHeight={584} index={2}/>} text={`Now, the sad part: your stuff does get lost. Not to fear, though...`}/>
                    <HowToStep index={3} content={<HowToImage imageHeight={574} index={3}/>} text={`Someone scans your tag and lets you know where your stuff is.`}/>
                    <HowToStep index={4} content={<HowToReport />} text={`Then, we'll notify & email you—so you can be reunited with your stuff.`}/>
                    <div className='demo-video-button'>
                
                <Link onClick={()=>{
                    logEvent(analytics, 'How To Pressed On Home Page')
                    window.scrollTo(0, 0);}}to={'/how-to'}>
                            <h2 className='margin-bottom-64  color-blue'>Already Have a Tag? Get Started →</h2>
                            </Link>
                            </div>
                </div>
               
            </div>
        </>
    )
}