import BackButton from "./back-button";

export default function StepIndicator(props: { link: string, index: number, canGoBack?: boolean }) {
    return (
        <div className='step-index-container'>
            {
                props.canGoBack ?
                    <BackButton linksTo={props.link}/>
                    :
                    null
            }
            <p className='quiet margin-bottom-16'>Step {props.index} / 3</p>
        </div>
    )
}